import React from "react";
import { route, lazy, mount } from "navi";
import Landing from "./landing";

const routes = mount({
  "/": route((request, context) => {
    return {
      title: "FARMASCREEN - Biochemical Systems International",
      view: <Landing user={context.user} isMobile={context.isMobile} />
    };
  }),
  "/login": lazy(() => import("./auth/Login")),
  "/register": lazy(() => import("./auth/Register")),
  "/reset-password": lazy(() => import("./auth/RequestPasswordReset")),
  "/update-password": lazy(() => import("./auth/UpdatePassword")),
  "/logout": lazy(() => import("./auth/Logout")),
  "/readings": lazy(() => import("./readings")),
  "/patient-data/:id": lazy(() => import("./patient-data"))
});

export default routes;
