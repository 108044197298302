import React from "react";
import firebase from "firebase/app";
import { NotFoundBoundary } from "react-navi";
import { Layout } from "antd";

import Nav from "./Nav";
import NotFound from "../routes/NotFound";

const { Content, Footer } = Layout;

type LayoutProps = {
  children: React.ReactNode,
  isLoading?: boolean,
  user?: firebase.User | null
};

function AppLayout({
  children,
  isLoading,
  isMobile,
  user,
  firebase
}: LayoutProps) {
  return (
    <div className="App">
      <Layout className="layout">
        <Nav
          isMobile={isMobile}
          user={user}
          loading={isLoading}
          firebase={firebase}
        />
        <Content className="main-content">
          <NotFoundBoundary render={() => <NotFound />}>
            {children}
          </NotFoundBoundary>
        </Content>
        <Footer style={{ textAlign: "center" }}>FARMASCREEN ©2019</Footer>
      </Layout>
    </div>
  );
}

export default AppLayout;
