import React, { useState, useRef } from "react";
import TweenOne from "rc-tween-one";
import { Menu, Icon } from "antd";
import { Link } from "react-navi";
import logo from "../assets/logo.svg";

const Nav = props => {
  const { isMobile, user } = props;

  const menu = useRef(null);

  const [phoneOpen, setPhoneOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);

  const phoneClick = () => {
    setPhoneOpen(open => !open);
    setMenuHeight(phoneOpen ? 0 : menu.current.dom.scrollHeight + 100);
  };

  const renderNavMenuItems = () => {
    let items = [
      <Menu.Item key="0">
        <Link href="/login">
          <Icon type="login" />
          Login
        </Link>
      </Menu.Item>
    ];
    if (user) {
      const { firstName, lastName } = user;
      items = [
        <Menu.SubMenu
          key="1"
          title={
            <span className="submenu-title-wrapper">
              <Icon type="setting" />
              {firstName} {lastName}
            </span>
          }
        >
          <Menu.Item key="12">
            <Link href="/update-password">
              <Icon type="sync" />
              Aggiorna password
            </Link>
          </Menu.Item>
          <Menu.Item key="13">
            <Link href="/logout">
              <Icon type="logout" />
              Logout
            </Link>
          </Menu.Item>
        </Menu.SubMenu>,
        <Menu.Item key="2">
          <Link href="/readings">
            <Icon type="profile" />I miei parametri
          </Link>
        </Menu.Item>
      ];
    }
    return items;
  };

  return (
    <TweenOne
      component="header"
      className="home-page-wrapper"
      animation={{ opacity: 0, type: "from" }}
    >
      <div className={`home-page${phoneOpen ? " open" : ""}`}>
        <TweenOne
          animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
          className="logo"
        >
          <Link href="/">
            <img src={logo} alt="img" />
          </Link>
        </TweenOne>
        {isMobile && (
          <nav
            className="mobile-menu"
            onClick={() => {
              phoneClick();
            }}
          >
            <em />
            <em />
            <em />
          </nav>
        )}
        <TweenOne
          component="nav"
          ref={menu}
          className="menu"
          animation={{ x: 30, type: "from", ease: "easeOutQuad" }}
          style={isMobile ? { height: menuHeight } : null}
        >
          <Menu
            mode={isMobile ? "inline" : "horizontal"}
            theme="default"
            // theme={isMobile ? "dark" : "default"}
          >
            {renderNavMenuItems()}
          </Menu>
        </TweenOne>
      </div>
    </TweenOne>
  );
};

export default Nav;
