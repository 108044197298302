import React from "react";
import { Button, Icon } from "antd";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import ScrollAnim from "rc-scroll-anim";
import logo from "../../assets/logo.svg";

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;

    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div {...currentProps} className="banner">
        <QueueAnim
          key="QueueAnim"
          className="banner-content-wrapper"
          type={["bottom", "top"]}
          delay={200}
        >
          <h1 key="content" className="banner-title">
            Benvenuto in
          </h1>
          <div key="title" className="banner-logo">
            <img src={logo} width="100%" alt="img" />
          </div>
          <ScrollAnim.Link to="home-page-section2">
            <Button ghost key="button" className="banner-button">
              Scopri di più
            </Button>
          </ScrollAnim.Link>
        </QueueAnim>
        <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000
          }}
          className="banner-icon"
          key="icon"
        >
          <Icon type="down" />
        </TweenOne>
      </div>
    );
  }
}
export default Banner;
