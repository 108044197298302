import React from "react";
import { Link } from "react-navi";
import { Button } from "antd";

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>Pagina non trovata</h1>
      <div>
        <Link href="/">
          <Button type="default">Torna alla Home</Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
