import React, { Suspense, useState, useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { withView, compose } from "navi";
import { Router, View } from "react-navi";
import Firebase from "./Firebase";
import { useMedia } from "react-use";

import routes from "./routes";
import AppLayout from "./components/AppLayout";

const routesWithLayout = compose(
  // Instead of rendering the latest `currentUser`, we render its value at the
  // time that the route was generated. Given that changing the user rebuilds
  // the route, it will always be up-to-date -- and doing it this way helps
  // avoid flashes of content when navigating between auth actions.
  withView((request, context) => {
    const { user, firebase, isMobile } = context;
    return (
      <AppLayout user={user} isMobile={isMobile} firebase={firebase}>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </AppLayout>
    );
  }),
  routes
);
const localUser = JSON.parse(localStorage.getItem("authUser"));

function App() {
  let [firebase] = useState(() => new Firebase());
  let [user, setUser] = useState(localUser);
  const isMobile = useMedia("(max-width: 767px)");

  useEffect(() => {
    const listener = firebase.onAuthUserListener(
      authUser => {
        localStorage.setItem("authUser", JSON.stringify(authUser));
        setUser(authUser);
      },
      () => {
        localStorage.removeItem("authUser");
        setUser(null);
      }
    );

    return () => {
      listener();
    };
  }, [firebase]);

  let context = {
    user,
    firebase,
    isMobile
  };
  // console.log("app user ", user);

  return (
    <Router routes={routesWithLayout} context={context}>
      <View />
    </Router>
  );
}

//export default process.env.NODE_ENV === "development" ? hot(App) : App;
export default process.env.NODE_ENV === "development" ? hot(App) : App;
