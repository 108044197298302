import React from "react";
import Banner from "../../components/landing/Banner";
import MeasureTypes from "../../components/landing/MeasureTypes";

const Landing = ({ user, isMobile }) => {
  return (
    <div>
      <Banner key="Banner" isMobile={isMobile} />
      <MeasureTypes key="MeasureTypes" user={user} isMobile={isMobile} />
    </div>
  );
};
export default Landing;
