import React from "react";
import QueueAnim from "rc-queue-anim";
import { Button, Row, Col, Icon } from "antd";
import { Link } from "react-navi";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";

import lipidico from "../../assets/lipidico.svg";
import antiossidanti from "../../assets/antiossidanti.svg";
import anziano from "../../assets/anziano.svg";
import ovulazione from "../../assets/ovulazione.svg";
import diabete from "../../assets/diabete.svg";

class MeasureTypes extends React.PureComponent {
  render() {
    const { user } = this.props;

    return (
      <div id="home-page-section2" className="home-page-section2-wrapper">
        <div className="home-page-section2">
          <Row className="title-wrapper">
            <Col
            // md={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 3 }}
            >
              <ul>
                <li>
                  <b>FARMASCREEN</b> è un{" "}
                  <b>analizzatore automatico di chimica clinica</b> per
                  autoanalisi in farmacia. Particolarmente veloce e sicuro,
                  garantisce un’azione efficace di controllo e prevenzione.
                </li>
                <li>
                  <b>FARMASCREEN COMBINA TUTTE LE ESIGENZE</b>: permette di
                  eseguire più di <b>30 parametri</b> diversi.
                </li>
              </ul>
            </Col>
          </Row>
          <OverPack playScale={0.2} className="">
            <QueueAnim type="bottom" key="block" leaveReverse component="div">
              <Row key="000" className="block-wrapper">
                <Col
                  key="10"
                  className="block"
                  //  xs={24} md={8}
                >
                  <div className="icon">
                    <img src={lipidico} height="100px" alt="img" />
                  </div>
                  <p className="content0-title">Profilo Lipidico</p>
                  {/* <div>children content children</div> */}
                </Col>
                <Col key="20" className="block">
                  <div>
                    <div className="icon">
                      <img src={antiossidanti} height="100px" alt="img" />
                    </div>
                    <p className="content0-title">
                      Profilo Lipidico <br /> Antiossidante
                    </p>
                  </div>
                </Col>
                <Col key="30" className="block">
                  <div>
                    <div className="icon">
                      <img src={anziano} height="100px" alt="img" />
                    </div>
                    <p className="content0-title">
                      Diabete
                      <br />
                      Profilo Lipidico <br />
                      Funzione Renale
                    </p>
                  </div>
                </Col>
                <Col key="40" className="block">
                  <div>
                    <div className="icon">
                      <img src={ovulazione} height="100px" alt="img" />
                    </div>
                    <p className="content0-title">
                      Ovulazione <br />
                      Anemia <br />
                      HCG
                    </p>
                  </div>
                </Col>
                <Col key="50" className="block">
                  <div>
                    <div className="icon">
                      <img src={diabete} height="100px" alt="img" />
                    </div>
                    <p className="content0-title">
                      Diabete <br />
                      Profilo Lipidico <br />
                      Funzione Renale
                    </p>
                  </div>
                </Col>
              </Row>
            </QueueAnim>
          </OverPack>
          <OverPack playScale={0.2} className="">
            <QueueAnim
              type="bottom"
              key="table title"
              leaveReverse
              component="div"
            >
              <h2 key="rrr" className="test-list-title">
                LISTA DEI TEST
              </h2>
            </QueueAnim>
            <QueueAnim
              type="bottom"
              key="table col"
              leaveReverse
              className=""
              component={Row}
            >
              <Col key="22" xs={24} md={8} className="test-wrapper">
                <div className="header">
                  <h3>TEST SU SANGUE</h3>
                  <span>Quantitativi</span>
                </div>
                <p>GLUCOSIO</p>
                <p>COLESTEROLO</p>
                <p>TRIGLICERIDI</p>
                <p>PROFILO LIPIDICO</p>
                <p>(CT/HDL/TRIG/LDL)</p>
                <p>HbA1c (EMOGLOBINA GLICATA)</p>
                <p>ACIDO URICO</p>
                <p>RADICALI LIBERI</p>
                <p>ACIDO LATTICO</p>
                <p>EMOGLOBINA</p>
                <p>EMATOCRITO</p>
                <p>ERITROCITI</p>
              </Col>
              <Col key="33" xs={24} md={8} className="test-wrapper">
                <div className="header">
                  <h3>TEST RAPIDI</h3>
                  <span>Semiquantitativi e Qualitativi</span>
                </div>
                <p>PSA</p>
                <p>GRAVIDANZA</p>
                <p>OVULAZIONE</p>
                <p>MICROALBUMINA</p>
                <p>FERRITINA</p>
                <p>CELIACHIA</p>
              </Col>
              <Col key="44" xs={24} md={8} className="test-wrapper">
                <div className="header">
                  <h3>TEST SU URINE</h3>
                  <span>Semiquantitativi</span>
                </div>
                <p>UROBILINOGENO</p>
                <p>BILIRUBINA</p>
                <p>CHETONI</p>
                <p>SANGUE</p>
                <p>PROTEINE</p>
                <p>NITRITI</p>
                <p>LEUCOCITI</p>
                <p>GLUCOSIO</p>
                <p>PESO SPECIFICO</p>
                <p>pH</p>
                <p>ACIDO ASCORBICO</p>
              </Col>
            </QueueAnim>
            <QueueAnim
              type="bottom"
              key="btn"
              leaveReverse
              className="btn-wrapper"
              component={Row}
            >
              {!user && (
                <Link key="ff" href="/login">
                  <Button type="primary" size="large">
                    <Icon type="login" />
                    Login
                  </Button>
                </Link>
              )}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default MeasureTypes;
