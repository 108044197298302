import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";








const config = {
  apiKey: "AIzaSyAyXSDJkPs3knhjreIh555Ew05sWE0vTZ4",
  authDomain: "farmascreen-e3326.firebaseapp.com",
  databaseURL: "https://farmascreen-e3326.firebaseio.com",
  projectId: "farmascreen-e3326",
  storageBucket: "farmascreen-e3326.appspot.com",
  messagingSenderId: "675226827736",
  appId: "1:675226827736:web:a6a962b060942550"
};

class Firebase {
  constructor() {

  /*	  
	let app; 
	if (!firebase.getApps().length) {
		app = firebase.initializeApp(config); 
	} else {
		app = firebase.getApp(); 
	}  
  */
  
    let app = firebase.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
    this.firestore = firebase.firestore;
  }

  // *** Auth API ***
  loginWithEmail = async (email: string, password: string) => {
    try {
      const results = await this.auth.signInWithEmailAndPassword(
        email,
        password
      );

      return results;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  createUserWithEmail = async (email: string, password: string) => {
    try {
      const results = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );

      return results;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  signOut = () => this.auth.signOut();

  passwordReset = email => this.auth.sendPasswordResetEmail(email);

  sendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  passwordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.getPatient(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // default empty roles
            if (dbUser && !dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            const _authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            };
            next(_authUser);
          });
      } else {
        fallback();
      }
    });

  // *** Patients API ***
  getPatient = uid => this.db.doc(`patients/${uid}`);

  getPatientByMail = mail =>
    this.db.collection(`patients`).where("mail", "==", mail);

  // *** guestPatients API ***
  getGuestPatient = uid => this.db.doc(`guestpatients/${uid}`);

  getGuestPatientByCode = async code =>
    this.db
      .collection(`guestpatients`)
      .where("codicefiscale", "==", code)
      .get()
      .then(querySnapshot => {
        const users = [];
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          users.push(doc.id);
        });

        return users.length > 0 ? users[0] : null;
      });

  // *** Measures API ***
  getMeasure = id => this.db.doc(`measures/${id}`);

  getMeasures = uid => {
    if (!uid) return;
    return this.db
      .collection("measures")
      .where("patientId", "==", uid)
      .orderBy("timestamp", "asc");
  };
}

export default Firebase;
